import React from "react";
 

import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";
import imgStyles from "assets/style/image.js"
import { Link } from "@material-ui/core";

function CommunityPage() {
  const UseStyles = makeStyles(styles);
  const UseImgStyles = makeStyles(imgStyles);

  const classes = UseStyles();
  const imgClass = UseImgStyles();

  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} >
          {community && <div style={{ color: 'black', padding: '50px', margin: '10vw auto' }} >
            <h2>Wspólnota {community.name}</h2>
            <img src={`/zdjecia/Wspólnoty/${community.name}.jpg`} alt="" style={{ maxWidth: '100%' }} />
            <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-evenly' }}>
              <div >
                <img src={`/zdjecia/Księża/${community.priest}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Opiekun: </h3>
                <h3>{community.priest}</h3>
              </div>
              <div >
                <img src={`/zdjecia/Liderzy/${community.lider}.jpg`} alt="" style={{ maxWidth: '80%', maxHeight: '200px', margin: '10% auto' }} />
                <h3 style={{ fontWeight: 'bold' }}>Lider: </h3>
                <h3>{community.lider}</h3>
              </div>
            </div>
            {community.meetings &&
              <>
                <h3 style={{ fontWeight: 'bold' }}>Spotkania: </h3>
                <h3>{community.meetings}</h3>
              </>
            }
            <h3 style={{ fontWeight: 'bold' }}>O wspólnocie: </h3>
            <h3>{community.description}</h3>
            {community.comming && community.comming.map((item, idx) => (
              <h3 key={idx}>{item}</h3>))}
            {community.news &&
              <>
                <h3>Aktualności: </h3>
                {community.news.map((item, idx) => (
                  <Link key={idx} href={`/${item.link}`} id={`#${item.link}`}>
                    <img src={`/${item.posterUrl}`} className={imgClass.img} alt={item.alt}/>
                  </Link>
                )
                )}
              </>
            }
          </div>}
        </div>
      </div>
    </Layout>
  );
}

 
  const community =     {
    "url": "effatha",
    "name": "EFFATHA",
    "lider": "Anna Nakonieczna",
    "priest": "ks. Marcin Fikus",
    "meetings": "Parafia pw. NSPJ w Kluczborku, piątek 19:00",
    "description":"Głównym charyzmatem Wspólnoty EFFATHA jest prowadzenie ludzi w uwielbieniu, poszerzanie naszych granic w otwieraniu się na 'nowe'. W pierwsze piątki miesiąca spotykamy się na eucharystii o godz. 19:30 w Parafii NSPJ w Kluczborku. W drugą niedzielę miesiąca jako Wspólnota służymy   czasie parafialnej mszy o godz. 15:00. We Wspólnocie działa Diakonia Miłosierdzia, dzięki której wymieniamy między sobą potrzebne sprzęty, usługi oraz służymy różnoraką pomocą w codzienności. Diakonia cichej modlitwy wstawienniczej – to grupa ludzi, która każdego dnia modli się  powierzonych intencjach. Diakonia modlitwy wstawienniczej – jesteśmy gotowi do indywidualnej posługi modlitwą wstawienniczą wszystkim tym, którzy takiej modlitwy potrzebują. Wieczory Uwielbienia.", 
    // "comming": [
    //     "Najbliższe terminy: ",
    //     "21 października godz. 19:00  - w trakcie wieczoru będzie okazja do Sakramentu Pokuty jak również będzie można skorzystać z modlitwy wstawienniczej. ",
    //     "18 listopada godz. 19:00 – po wieczorze zapraszamy wszystkich na Agapę do auli ",
    //     "16 grudnia godz. 19:00 - w trakcie wieczoru będzie okazja do Sakramentu Pokuty jak również będzie można skorzystać z modlitwy wstawienniczej."
    // ],
    "news": [
        {
          "link": "effatha-wieczor-uwielbienia-2020-02-24",
          "posterUrl": "communitiesSlides/effatha/effatha-wieczor-uwielbienia-2020-02-24.jpg",
          "alt": "effatha wieczor uwielbienia 2020 02 24"
        },{
          "link": "effatha-urodziny-2019",
          "posterUrl": "communitiesSlides/8-rocznica-effathy.jpg",
          "alt": "effatha urodziny 2019"
        },{
          "link": "effatha-spotkanie-oplatkowe-2019",
          "posterUrl": "communitiesSlides/effatha-spotkanie-oplatkowe-2019.jpg",
          "alt": "effatha spotkanie oplatkowe 2019"
        }
    ]
  }
   

export default CommunityPage;
